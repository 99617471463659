import Type from "src/utils/type";

/**
 * 表示一个医院类型。
 * @enum
 * @version 1.0.0
 */
enum HospitalType
{
    /**
     * 其它。
     * @member
     */
    none = 0,

    /**
     * 公立三甲
     * @member
     */
    threeALevel = 1,

    /**
     * 公立医院
     * @member
     */
    public = 2,

    /**
     * 私立医院
     * @member
     */
    private = 3,

    /**
     * 体检机构
     * @member
     */
    professional = 4,

    /**
     * 专场
     * @member
     */
    specialShow = 5
}

/*
* 定义枚举元数据。
*/
Type.setMetadata(HospitalType,
{
    none:
    {
        alias: "其它",
        description: ""
    },
    threeALevel:
    {
        alias: "公立三甲",
        description: ""
    },
    public:
    {
        alias: "公立医院",
        description: ""
    },
    private:
    {
        alias: "私立医院",
        description: ""
    },
    professional:
    {
        alias: "体检机构",
        description: ""
    },
    specialShow:
    {
        alias: "专场",
        description: ""
    }
});
export
{
    HospitalType
};
