
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import { GaodeService, OrganizationService } from "src/services";

/**
 * 切换定位城市
 * @class
 * @version 1.0.0
 */
export default class LocationcityService extends ServiceBase
{

    /*
     * 城市服务实例
     * @private
     * @return GaodeService
     */
    private static _instance: LocationcityService;

    /**
     * 城市服务实例
     * @static
     * @property
     * @returns LocationcityService
     */
    public static get instance(): LocationcityService
    {
        if(!this._instance)
        {
            this._instance = new LocationcityService();
        }
        
        return this._instance;
    }

    /**
     * 获取切换定位城市
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async locationCity(productId: number | string): Promise<IHttpResponse>
    {

        let response = await this.apis.locationCity({params: { productId }});

        return response;
    }

    /**
     * 获取切换城市
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getRegion(): Promise<IHttpResponse>
    {

        let response = await this.apis.getRegion({});

        return response;
    }
}
