
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import { GaodeService, OrganizationService } from "src/services";

/**
 * 添加常用体检人服务
 * @class
 * @version 1.0.0
 */
export default class FamilyService extends ServiceBase
{

    /*
     * 家属服务实例
     * @private
     * @return FamilyService
     */
    private static _instance: FamilyService;

    /**
     * 家属服务实例
     * @static
     * @property
     * @returns FamilyService
     */
    public static get instance(): FamilyService
    {
        if(!this._instance)
        {
            this._instance = new FamilyService();
        }
        
        return this._instance;
    }

    /**
     * 新增/修改常用体检人
     * @public
     * @param {any} data 需要操作的数据
     * @param {boolean} isUpdate 是否是编辑
     * @returns {Promise<IHttpResponse>}
     */
    public async modifyFamily(data: any): Promise<IHttpResponse>
    {
        let response;

        if(data.employerMemberId)
        {
            // 编辑
            response = await this.apis.updateFamily({
                data: data
            });
           
        }
        else
        {
            // 新增
            response = await this.apis.addFamily({
                data: data
            });
        }

        return response;
    }

    /**
     * 添加常用体检人家属数据
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getFamilyList(params?): Promise<IHttpResponse>
    {

        let response = await this.apis.getFamilyList({
            params:{isQueryReport:1}
        });

        return response;
    }

    /**
     * 获取体检用户信息详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getFamilyDetail(userId: number): Promise<IHttpResponse>
    {

        let response = await this.apis.getFamilyDetail({
            data:
            {
                id: userId
            }
        });

        return response;
    }

    /**
     * 检查个人信息是否完善
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async checkIsPerfect(): Promise<IHttpResponse>
    {

        let response = await this.apis.checkIsPerfect({});

        return response;
    }


}
