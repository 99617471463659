
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class PackageComponent extends Vue
{
    /**
     * 是否显示价格
     * @private
     * @returns string
     */
    @Prop({type: Boolean})
    private price: true;
    
    /**
     * 是否显示划线价
     * @private
     * @returns string
     */
    @Prop({type: Boolean})
    private scribe: true;
    
    /**
     * 套餐数据
     * @private
     * @returns string
     */
    @Prop({type: Object})
    private item: any;
    
    /**
     * 是否显示市场价格
     * @private
     * @returns string
     */
    @Prop({type: Boolean, default: true})
    private isPackageMarketPrice: boolean;

    /**
     * 是否显示价格
     * @private
     * @returns string
     */
    @Prop({type: Boolean, default: true})
    private isPackagePrice: boolean;

    /**
     * 1:本人 2 其他 
     * @private
     * @returns string
     */
    @Prop({type: Number, default: 0})
    private packageType: number;
    

}
