const globalSetting = (<any>window).globalSetting;

const globalConfig =
{
    server:  globalSetting.apiServer,
    isDebug:  globalSetting.isDebug,
    vivoRedirectUrl:  globalSetting.vivoRedirectUrl,
    showRightsCode:  globalSetting.showRightsCode,
    sensorsConfig:  globalSetting.sensorsConfig,
    employerConfig:  globalSetting.employerConfig,
    signalrUrl:  globalSetting.signalrUrl
};

export default globalConfig;
