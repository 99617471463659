import Type from "src/utils/type";

export
{
    ORDER_STATUS
};

/**
 * 订单状态
 * @enum
 * @version 1.0.0
 */
enum ORDER_STATUS
{
    /**
     * 已取消
     * @member
     */
    cancel = 0,

    /**
     * 待付款
     * @member
     */
    unpaid,
    
    /**
     * 已预约，未超额
     * @member
     */
    reserved,

    /**
     * 已预约，已超额
     * @member
     */
    excess,

    /**
     * 已完成
     * @member
     */
    finish = 5
   
}

Type.setMetadata(ORDER_STATUS,
{
    cancl:
    {
        description: "已取消"
    },
    unpaid:
    {
        description: "待付款"
    },
    reserved:
    {
        description: "已预约"
    },
    excess:
    {
        description: "已预约"
    },
    finish:
    {
        description: "已完成"
    }
});
