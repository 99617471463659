const orderDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/order/order-detail.vue")), "order");
const orderList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/order/order-list.vue")), "order");

export default
[
    {
        path: "/order/detail/:id",
        name: "orderDetail",
        component: orderDetail,
        meta:
        {
            title: "体检订单详情"
        }
    },
    {
        path: "/order/list",
        name: "order-list",
        component: orderList,
        meta:
        {
            title: "我的订单"
        }
    },
];
