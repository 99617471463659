let sensors = require('sa-sdk-javascript');
import pageleave from 'src/utils/sensors/pageleave';
import pageload from 'src/utils/sensors/pageload';
import config from 'src/config';

if(config.sensorsConfig.enable)
{
    // 注册公共属性
    sensors.registerPage({
        platform_name:"企业版（含个检）H5"
    });
    // 页面加载时长是分析
    // https://github.com/sensorsdata/sa-sdk-javascript/tree/master/dist/web/plugin/pageload
    sensors.use(pageload);

    // 注册页面浏览时长是网站分析插件
    sensors.use(pageleave,{
        custom_props:{
        //   prop1:'value1'
        },
        heartbeat_interval_time: 5,
        max_duration: 5 * 24 * 60 * 60,
        isCollectUrl: function(url){
        // url 为要采集页面浏览时长的页面地址。
        // return true; // 采集
        return false; // 不采集
        }
    });
    
    sensors.init({
        server_url: config.sensorsConfig.url,
        heatmap:{
            //是否开启触达图，default 表示开启，自动采集 $WebStay 事件，可以设置 'not_collect' 表示关闭。
            //需要 Web JS SDK 版本号大于 1.9.1
            scroll_notice_map:'default',
            // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
            // 默认只有点击 a input button textarea 四种元素时，才会触发 $WebClick 元素点击事件
            clickmap:'default',
            get_vtrack_config: true,
            collect_tags: {
                div: {
                    max_level: 3 // 默认是 1，即只支持叶子 div。可配置范围是 [1, 2, 3],非该范围配置值，会被当作 1 处理。
                },
                li: true,
                img: true,
                svg: true,
                span: true,
                i: true
            }
        },
        // show_log: config.sensorsConfig.debug,
        is_track_single_page:true,
        use_client_time:true,
        send_type:'beacon'    
    });

    // 设置之后，SDK 就会自动收集页面浏览事件，以及设置初始来源。
    // sensors.quick('autoTrack');
}

export default sensors;