import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import aiAssistant from "./modules/ai/assistant";
import order from "./modules/order";



// 神策
import sensors from "../sensors"
Vue.use(VueRouter);

const routes: Array<RouteConfig> =
[
    {
        path: "/",
        redirect: "/ai"
    },
    ...aiAssistant,
    ...order,
];


let urlArr = location.pathname.split("/");
let appid = urlArr[1] || "aia";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL + `${appid}/`,
  routes
});

router.beforeEach((to, from, next) =>
{
    let orgName = "";
    let orgInfoStorge = localStorage.getItem("orgInfo");
    if(orgInfoStorge)
    {
        orgName =  JSON.parse(orgInfoStorge).orgName
    }
    if(to.meta.title || orgName)
    {
        document.title = to.meta.title || orgName;
    }
    
    let urlArr = location.pathname.split("/");
    let appid = urlArr[1] || "aia";
    let appidStorage = localStorage.getItem("appid");
    // 注册公共属性
    sensors.registerPage({
        company_name: orgName || "",
        company_code: appidStorage || "",
    });
    if(appidStorage && appid !== appidStorage)
    {
        localStorage.clear();
        next({name:"ai"});
        localStorage.setItem("appid", appid);
        
    }else
    {
        localStorage.setItem("appid", appid);
    }

    next();
});

router.afterEach((to,from) => {
	Vue.nextTick(() => {
		sensors.quick("autoTrackSinglePage");
	});
});


export default router;
