
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import IHttpRequest from "src/http/http-request";
import { rejects } from "assert";
import { resolve } from "path";
import { loadBMap } from 'src/utils'

/**
 * 坐标系转换
 * @public
 * @param {params}
 * @returns {Promise<any>}
 */
 function bMapTrans(lng, lat) {
    let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
    let x = lng - 0.0065;
    let y = lat - 0.006;
    let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
    let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
    let lngs = z * Math.cos(theta);
    let lats = z * Math.sin(theta);
    
    return {
        lng: lng,
        lat: lat        
    }   
}

/**
 * 高德服务。
 * @class
 * @version 1.0.0
 */
export default class GaodeService extends ServiceBase
{
    /*
     * 高德服务实例
     * @private
     * @return GaodeService
     */
    private static _instance: GaodeService;

    /**
     * 高德服务实例
     * @static
     * @property
     * @returns GaodeService
     */
    public static get instance(): GaodeService
    {
        if(!this._instance)
        {
            this._instance = new GaodeService();
        }
        
        return this._instance;
    }

    /**
     * 获取当前定位信息
     * @public
     * @param {params}
     * @returns {Promise<any>}
     */
    public async getBaiduLocation(): Promise<any>
    {
        console.log("获取定位")
        let ak = 'G3GseWqtFYdU4pC0j6Y7eKTrFFlncOFm';
        let data =
        {
            city: "深圳市",
            lng: 114.012325,
            lat: 22.540269
        };

        return new Promise<any>((resolve, reject) =>
        {
            loadBMap(ak).then(map=>
            {
                let BMapGL: any = map;
                let geolocation = new BMapGL.Geolocation();
                geolocation.getCurrentPosition(function(r){
                    if(this.getStatus() == 0)
                    {
                        let lng = r.point.lng;
                        let lat = r.point.lat;
                        let gaode = bMapTrans(lng, lat);
                        data.lat = gaode.lat;
                        data.lng = gaode.lng;
                        data.city = r.address.city;
                        resolve(data);
                    }
                    else 
                    {
                        let orgInfoStorge = localStorage.getItem("orgInfo");
                        let orgInfo = orgInfoStorge && JSON.parse(orgInfoStorge);

                        data.lat = orgInfo && orgInfo.latitude || 22.540269;
                        data.lng = orgInfo && orgInfo.longitude || 114.012325;
                        data.city = orgInfo && orgInfo.cityName || "深圳市";
                        resolve(data);
                    }        
                })
            }).catch(err =>
            {
                console.log(err);
            })
        })
        
    }

    /**
     * 获取当前定位信息
     * @public
     * @param {params}
     * @returns {Promise<any>}
     */
    public async getGeolocation(): Promise<any>
    {
        return new Promise<any>((resolve, reject) =>
        {
            let data =
            {
                city: "",
                lng: null,
                lat: null
            };

            AMap.plugin("AMap.Geolocation", function() {
                let geolocation = new AMap.Geolocation({
                    // 是否使用高精度定位，默认：true
                    enableHighAccuracy: true,
                    // 设置定位超时时间，默认：无穷大
                    timeout: 2000,
                    // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                    buttonOffset: new AMap.Pixel(0, 0),
                    //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                    zoomToAccuracy: true,
                    //  定位按钮的排放位置,  RB表示右下
                    buttonPosition: "RB"
                });
                
                geolocation.getCurrentPosition((status: string,result: any) =>
                {
                    if(status === "complete")
                    {
                        data.lat = result.position.lat;
                        data.lng = result.position.lng;
                        data.city = result.addressComponent && result.addressComponent.city;
                       
                        resolve(data);
                    }
                    else
                    {
                        let orgInfoStorge = localStorage.getItem("orgInfo");
                        let orgInfo = orgInfoStorge && JSON.parse(orgInfoStorge);

                        data.lat = orgInfo && orgInfo.latitude || 22.540269;
                        data.lng = orgInfo && orgInfo.longitude || 114.012325;
                        data.city = orgInfo && orgInfo.cityName || "深圳市";
                        resolve(data);
                    }
                });
                // geolocation.getCityInfo((status: string,result: any) =>
                // {
                //     console.log("获取城市")
                //     console.log(status)
                //     console.log(result)
                //     if(status === "complete")
                //     {                      
                //         data.city = result.city;
                //         if(data.lat)
                //         {
                //             resolve(data);
                //         }
                //     }
                //     else
                //     {
                //         let orgInfoStorge = localStorage.getItem("orgInfo");
                //         let orgInfo = orgInfoStorge && JSON.parse(orgInfoStorge);

                //         data.lat = orgInfo && orgInfo.latitude || 22.540269;
                //         data.lng = orgInfo && orgInfo.longitude || 114.012325;
                //         data.city = orgInfo && orgInfo.cityName || "深圳市";
                //         resolve(data);
                //     }
                // });
            });
        });
    }

    /**
     * 根据经纬度获取城市名
     * @public
     * @param {params}
     * @returns {Promise<any>}
     */
    public async getCity(lng : number, lat : number): Promise<any>
    {
        return new Promise<any>((resolve, rejects) => {
            let data =
        {
            city: "",
            lng: lng,
            lat: lat
        };
        AMap.plugin('AMap.Geocoder', function() {
            var geocoder = new AMap.Geocoder({
              // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
              city: '010'
            })

            geocoder.getAddress([data.lng, data.lat], function(status, result) {
                if (status === 'complete' && (result as any).info === 'OK') {
                    // result为对应的地理位置详细信息
                    let province = (result as any).regeocode.addressComponent.province;
                    if (province === "上海市" || province === "天津市" || province === "重庆市" || province === "北京市")
                    {
                        data.city = province;
                    }
                    else
                    {
                        data.city = (result as any).regeocode.addressComponent.city || (result as any).regeocode.addressComponent.district
                    }
                    resolve(data);
                }
                else
                {
                    let orgInfoStorge = localStorage.getItem("orgInfo");
                    let orgInfo = orgInfoStorge && JSON.parse(orgInfoStorge);

                    data.lat = orgInfo && orgInfo.latitude || 22.540269;
                    data.lng = orgInfo && orgInfo.longitude || 114.012325;
                    data.city = orgInfo && orgInfo.cityName || "深圳市";
                    resolve(data);
                }
            })
          })
        })
    }


    
}
