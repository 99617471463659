import Type from "src/utils/type";

export
{
    ORG_TYPE
};

/**
 * 机构类型
 * @enum
 * @version 1.0.0
 */
enum ORG_TYPE
{
    /**
     * 公立三甲
     * @member
     */
    kab = 1,

    /**
     * 公立医院
     * @member
     */
    public,
    
    /**
     * 民营医院
     * @member
     */
    privately,

    /**
     * 专业体检机构
     * @member
     */
    org,

    /**
     * 按距离
     * @member
     */
    distance
   
}

Type.setMetadata(ORG_TYPE,
{
    kab:
    {
        description: "公立三甲"
    },
    public:
    {
        description: "公立医院"
    },
    privately:
    {
        description: "民营医院"
    },
    org:
    {
        description: "体检机构"
    },
    distance:
    {
        description: "按距离"
    }
});
