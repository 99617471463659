import Type from "src/utils/type";

export
{
    Payment_Type
};

/**
 * 支付方式
 * @enum
 * @version 1.0.0
 */
enum Payment_Type
{
    /**
     * 微信:公众号支付
     * @member
     */
    WechatJSAPI = 1,

    /**
     * 微信:H5支付
     * @member
     */
    WechatMWEB,
    
    /**
     * 微信:扫码NATIVE
     * @member
     */
    WechatNATIVE,

    /**
     * 微信:APP支付
     * @member
     */
    WechatAPP,

    /**
     * 微信小程序
     * @member
     */
    WechatMiniProgram,

    /**
     * 支付宝手机网站支付
     * @member
     */
    AliPayWap,

    /**
     * 支付宝电脑网站支付
     * @member
     */
    AliPayPage
   
}

Type.setMetadata(Payment_Type,
{
    WechatJSAPI:
    {
        description: "微信:公众号支付"
    },
    WechatMWEB:
    {
        description: "微信:H5支付"
    },
    WechatNATIVE:
    {
        description: "微信:扫码NATIVE"
    },
    WechatAPP:
    {
        description: "微信:APP支付"
    },
    WechatMiniProgram:
    {
        description: "微信小程序"
    },
    AliPayWap:
    {
        description: "支付宝手机网站支付"
    },
    AliPayPage:
    {
        description: "支付宝电脑网站支付"
    }
});
