
import { Component, Vue, Watch } from "vue-property-decorator";
import { FeedbackService,EmployerGeneralSettingsService } from "src/services";
import { Toast, Dialog } from "vant";

@Component
export default class App extends Vue
{
  
    /**
     * 当路由发生变化的时候操作。
     * @protected
     * @param {any} - to 当前路由实例。
     * @returns {void}
     */
    @Watch("$route", {deep: true})
    protected updateOpen(to: any): void
    {
        if (window && window.dsBridge)
        {
            if (to.name == 'home' || to.name == 'personal-center' || to.name == 'dsBridgeAuth')
            {
                dsBridge.call("ui.configureBackButton", {hidesBackButton : true});
            }
            else
            {
                dsBridge.call("ui.configureBackButton", {hidesBackButton : false});
            }
        }
        let whiteName = ["ai-assistant", "ai"];

        // if(whiteName.indexOf(to.name) === -1)
        // {
        //     this.getGeneralSettings();
        // }
    }

    /**
     * 获取企业参数配置
     * @private
     * @returns void
     */
     private async getGeneralSettings(): Promise<void>
    {
        try
        {
            let {content: result} = await EmployerGeneralSettingsService.instance.employerGeneralSettingsList();
            if(result.data)
            {
                this.$store.dispatch("setGeneralSettings", result.data);
            }
        }catch(err)
        {
            Toast(JSON.stringify(err));
        }
        
    }

    /**
     * loading次数
     * @private
     * @returns string
     */
    private get loadingCount(): number
    {
        return this.$store.state.loadingCount || 0;
    }

    /**
     * 获取企业信息
     * @private
     * @returns any
     */
    private get orgInfo(): any
    {
        let orgInfoStorge = localStorage.getItem("orgInfo");
        if(orgInfoStorge)
        {
            return JSON.parse(orgInfoStorge)
        }
        return {};
    }

}
